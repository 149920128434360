import { State } from "@/types/types.interface";
import { Store } from "vuex";

export const sendNotification = (
  msg: string,
  error: number,
  store: Store<State>
) => {
  store.dispatch("setNotification", {
    msg: msg,
    error: error,
  });
};
